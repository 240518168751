.equipment-info-box {
  min-height: 85px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  padding: 32px;
}

.info-status { 
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: var(--gray-dark);
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  margin-top: 23px;
  &.status-normal{
    background-color: var(--success);
  }
  &.status-unavailable{
    background-color: var(--gray-500);
  }
  &.status-defect{
    background-color: var(--danger);
  }
}
