.info-status {
   &.alarm-SOLVED {
      background-color: var(--success);
   }

   &.alarm-DUPLICATE {
      background-color: var(--warning);
   }

   &.alarm-FALSE_ALERT {
      background-color: var(--danger);   }
}
.alarm-type-header{
   padding-left: 80px;
}

.alarm-btn{
   min-width: 170px;
   &.download-btn{
      margin-right: 20px;
   }

}
.ack-comment{
   &::placeholder {
      color: var(--gray);
   }
}

