.breadcrumb-container{
  width: 420px;
  .breadcrumb {
    padding-top: 0.5rem;
    background-color: var(--gray-0);

    .breadcrumb-item {
      & + .breadcrumb-item {
        &::before {
          content: ">";
        }
      }
    }
  }
}
