.history-modal-title {
  margin-top: -44px;
  margin-left: -35px;
}

.legend-year {
  background-color: #84DFFF;
  width: 15px;
  height: 15px;
  position: absolute;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;

  &.compare {
    background-color: #cccccc;
  }
}
.list-table {
  th {
  &:before {
    float: left !important;
    }
  }
  &.consumption{
    overflow-x: unset;
  }
}
