.map{
    width: 100%;
    height: 100%;
}

.hidden{
    visibility: hidden;
    display:none;
}

.close-pane{
    position: absolute;
    z-index: 999;
    width: 5%;
    height: 3rem;
    text-align: center;
    background-color: #030f40;
    color: white;
    left: -18px;
    font-size: 25px;        
}

.open-pane{
    width: 2%;
    background-color: white;
    div{
        text-align: center;
        background-color: #030f40;
        color: white;
        font-size: 25px;
    }
}

.state-span{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: inline-block;

    &.to-follow{
        background-color: #30D158;
    }
    &.to-not-follow{
        background-color: #800E21;
    }
    &.to-follow-not-connectable {
        background-color: #FF8363;
    }
}

.list-table {
    .status-img{
        max-width: 50px;
    }
}

.alarm-box-info
{
    height: 260px;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    .big{
        font-size: 2.2em;
        margin-left: -80px;
        margin-bottom: -10px;
    }
}

.weather-box{
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: auto;
    margin-bottom: 90px ;

}
