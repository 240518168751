.alm-ev-container {
  height: calc(100% - 60px);
  display: flex;
  flex-wrap: nowrap;

  &.connected {
    height: 100%;
    width: 100%;
    transition: 0.5s ease-in;
  }
  &.isOpen {
    transition: 0.3s ease;
  }
}
.alm-ev-aside {
  height: 100%;
  &.sz-aside-exit-done{
      width: 48px;
  }
  &.open{
    width: 198px;
    .alm_admin {
      &::before {
        width: 100%;
      }
    }
  }
  .alm_admin {
    bottom: 0;
    position: absolute;
    width: fit-content;
    padding: 16px;
    height: 210px;

    &::before {
      content: '';
      position: absolute;
      background: var(--primary);
      top: 0;
      bottom: 90%;
      left: 0;
      width: 90%;
    }

    &__item {
      height: 60px
    }
  }
}
.alm-ev-main-container {
    display: flex;
    // 45 = height of header
    height: calc(100% - 45px);
}
.no-padding{
  padding: 0;
}

.clickable {
  cursor: pointer !important;
}

.container-content{
  width: 100%;
}
.search-box{
  .input-group{
    margin-bottom: 0px !important;
  }

}

