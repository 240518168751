.module-circuit-line-pair{
  background-color: #EAEDF4;
}

.info-status { 
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: var(--gray-dark);
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  margin-top: 23px;
  &.info-status-circuit
  {
    margin-top: 18px;
  }
  &.info-status-module
  {
    margin-top: 5px;
  }
  &.status-on{
    background-color: var(--success);
  }
  &.status-off{
    background-color: var(--gray-500);
  }
  &.status-partial{
    background-color: var(--warning);
  }
  &.status-down{
    background-color: var(--danger);
  }
}

.legend-watering-humidity {
  background-color: #84DFFF;
  width: 15px;
  height: 15px;
  position: absolute;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;

  &.humidity {
    background-color: red;
  }
}

.chart-text{
font-size: 12px;
}
.btn-programming{
  min-width: 170px;
}
@media (min-height: 1200px) {
  .programming-device-name {
    margin-left: 100px;
  }
}


.circuit-box {
  &.circuit-on {
    .sz-left-border {
      &::after {
        background-color: var(--success);
      }
    }
  }

  &.circuit-off {
    .sz-left-border {
      &::after {
        background: var(--gray-500);
      }
    }
  }

  &.circuit-partial {
    .sz-left-border {
      &::after {
        background: var(--warning);
      }
    }
  }
}
.alm-ev-container {
  .sz-accordion {
    .sz-collapse__header {
    box-shadow: unset;
    }

  }
}
.program-time-input{
  input{
    min-width: 52px !important;
  }
}

.delete-watering-at {
  max-width: 30px;
  max-height: 37px;
}

.station-list {
  max-height: 400px;
  overflow-x: auto;
}
