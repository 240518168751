.nav
{
  &.nav-tabs
  {
    .nav-link
    {
      &.active
      {
        border-bottom: 2px solid var(--primary);
        color: var(--primary-dark);
      }
    }
  }
}