@import '~leaflet/dist/leaflet.css';

.leaflet-container {
  height: 90%;
  width: 70%;

  .leaflet-popup {
    .leaflet-popup-close-button {
      display: none;
    }
  }

  .leaflet-draw-toolbar {
    .leaflet-draw-draw-polygon {
      background-position: -54px -6px;
      width: 35px;
      height: 35px;
      background-size: 484px auto;
    }

    .leaflet-draw-edit-edit {
      background-position: -212px -3px;
      width: 35px;
      height: 35px;
      background-size: 420px auto;
    }

    .leaflet-draw-edit-remove {
      background-position: -207px -2px;
      width: 35px;
      height: 35px;
      background-size: 346px auto;
    }
  }

  .leaflet-control-container {
    .leaflet-control-zoom-in {
      width: 35px;
      height: 30px;
      background-size: 484px auto;
    }

    .leaflet-control-zoom-out {
      width: 35px;
      height: 30px;
      background-size: 484px auto;
    }
  }
}

.project-container {
  .leaflet-container {
    height: 100%;
  }
}

.map-filter {
  background-color: #fff;
  height: 90%;
  width: 25%;
  .search-map{
   margin-right: 2px !important;
  }
  .watering-plant-filter {
    .btn-img {
      background-color: #7d8492;
    }

    &.watering-not-zero {
      .btn-img {
        background-color: #0d5aa7;
      }
    }

    &.watering-zero {
      .btn-img {
        background-color: #00bb00;
      }
    }

    .btn-text {
      margin-left: 12px;
    }

    &.nd {
      .btn-text {
        margin-left: 22px;
      }
    }
  }


  .humidity-plant-filter {
    .btn-img {
      background-color: #7d8492;
    }

    &.humidity-1{
      .btn-img {
        background-color: #7d8492;
      }
    }
    &.humidity-2{
      .btn-img {
        background-color: #FAB51A;
      }
      .btn-text {
        margin-left: 12px;
      }
    }
    &.humidity-3{
      .btn-img {
        background-color: #55C277;
      }
      .btn-text {
        margin-left: 12px;
      }
    }
    &.humidity-4{
      .btn-img {
        background-color: #406AE0;
      }
    }
  }

  .risk-plant-filter {
    &.risk-ok {
      .btn-img {
        background-color: #00bb00;
      }
    }

    &.risk-satur {
      .btn-img {
        background-color: #0000cc;
      }
    }

    &.risk-vigilance {
      .btn-img {
        background-color: #cc8b00;
      }
    }

    &.risk-alert {
      .btn-img {
        background-color: #FF0000;
      }
    }

    &.risk-crise {
      .btn-img {
        background-color: #770088;
      }
    }
    &.risk-nd {
      .btn-img {
        background-color: #7d8492;
      }
    }
  }

  .RRT__accordion {
    flex-direction: inherit;
  }

  #tab-tab-equipment {
    position: absolute;
    top: 0;
    left: 110px
  }

  #tab-tab-plant-watering {
    padding-left: 5px;
    padding-right: 5px;
  }

  #tab-tab-plant-humidity {
    padding-left: 5px;
    position: absolute;
    top: 0;
    left: 82px;
  }

  #tab-tab-plant-risk {
    position: absolute;
    top: 0;
    left: 164px
  }

  .info-box {
    background: #fff;
    padding: 32px;

    &.location-ref {
      overflow-y: auto;
      max-height: 800px;

      .location-ref-title {
        height: 50px;
        border-left: 4px solid var(--primary-brand);
      }
    }
  }

  .filter-icon {
    margin-top: -10px;
  }
}

.filter-btn {

  height: 110px;
  width: 105px;
  float: left;
  border: 1px solid #000;

  .btn-text {
    margin-left: 20px;
    font-size: 13px;
  }
}

.btn-img {
  width: 34px;
  height: 34px;
  display: block;
  margin-top: 30px;
  margin-left: 32px;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.list-filter {
  .RRT__panel{
    width: 100% !important;
  }
  &.list-filter-alarm {
    .filter-btn {
      .btn-text {
        margin-left: 22px;

      }
    }
  }

  .filter-btn {
    .btn-text {
      &.btn-text-equipment {
        margin-left: 3px;
      }
    }

    .btn-img {
      &.on {
        background-color: #30d158;
      }

      &.off {
        background-color: red;
      }

      &.unavailable {
        background-color: var(--gray-500);
      }

      &.partial {
        background-color: var(--warning);
      }


      &.alarm {
        background-color: var(--warning);

        i {
          margin-left: 4px;
        }
      }
    }

    &.not-active {
      opacity: 0.3;
    }
  }

  .nav-tabs {
    width: 210px;
    margin-left: 10px;

    .nav-item {
      margin-bottom: 0px;
    }

    background-color: var(--secondary);
    color: white;

    .nav-link {
      font-size: 14px;
      margin-top: 4px;
      margin-bottom: 4px;
      margin-left: 4px;

      &.active {
        color: var(--secondary);
        background-color: white;
        border-bottom: 0px;
        border-radius: 2px;
      }
    }
  }
}