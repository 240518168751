.alarm-tab {
  .nav {
    &.nav-tabs {
      .nav-link {
        background-color: #fff;
        border-bottom: 4px solid #ccc;
        margin-right: 20px;
        padding-left: 50px;
        padding-right: 50px;
        font-weight: bold;

        &.active {
          border-bottom: 4px solid var(--primary-brand);
        }
      }
    }

    .tab-pane {
      &.active {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.alarm-info-box {
  height: 142px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  padding: 20px;
}
.alarm-info-title {
  margin-left: 15px;

}
.alarm-info-nbr {
  width: 30px;
  height: 30px;
  position: absolute;
  background: var(--primary-brand);
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  margin-left: -17px;
  color:#fff;
  text-align: center;
  font-size: 1rem;
  padding-top: 2px;}

.alarm-title-box {
  height: 80px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  padding: 20px;
}

.alarm-header-box {
  height: 50px;
  background: #f4f8ff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}