.location-reference-box {
  &.location-reference-on {
    .sz-left-border {
      &::after {
        background-color: var(--success);
      }
    }
  }

  &.location-reference-off {
    .sz-left-border {
      &::after {
        background: var(--gray-500);
      }
    }
  }

  &.location-reference-partial {
    .sz-left-border {
      &::after {
        background: var(--warning);
      }
    }
  }
}
.info-status {
  &.location-reference-line-pair {
    background-color: #EAEDF4;
  }

  &.humidity-blue {
    background-color: #406AE0;
  }

  &.humidity-green {
    background-color: #55C277;
  }

  &.humidity-yellow {
    background-color: #FAB51A;
  }

  &.humidity-red {
    background-color: #7d8492;
  }
}

.location-reference-line-pair{
  background-color: #EAEDF4;
}


.hydrique-list
{
  td{
    padding: 0 ;
  }
  th{
    width: 70px !important;
    padding: 0 !important;
  }
  .rapport,.measure,.detail{
    width: 70px;
  }
    .text-name{
      width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  .text-variety-name{
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-equipment-name{
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-location-reference{
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .risk-text
  {
    text-align: center;
    width: 70px;
    padding: 2px;
    background-color: #9fa9b7;
    color: white;
    &.risk-ok{
      background-color: #00bb00;
    }
    &.risk-satur{
      background-color:#0000cc;
    }
    &.risk-vigilance{
      background-color:#cc8b00;
    }
    &.risk-alert{
      background-color:#FF0000;
    }
    &.risk-crise{
      background-color:#770088;
    }

  }

  .watering-text{
    .watering-icon{
      color: #7d8492;
      &.watering-not-zero
      {
        color:#0d5aa7;
      }
      &.watering-zero
      {
        color:#00bb00;
      }
    }
  }
}

@media (min-width: 2000px)
{
  .watering-content-tab{
    min-width: 500px;
  }
}