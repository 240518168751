@import "./main/styles/_colors";

html, body, #alm-ev-app {
  height: 100%;
  background-color: var(--gray-0);
  font-family: 'DINPro-Regular';
}
.alm-ev-content-container {
  max-width: 1100px;
}
.overflow-auto {
  overflow: auto;
}

.list-table {
  overflow-x:auto ;
  height: 100%;
  div {
    width: 100%;
  }
  thead{
    tr {
      background: none !important;
    }
  }
  td {
    vertical-align: middle;
  }
  th {
    font-size: 1rem !important;
    font-weight: bold !important;

    &:before {
      float: right;
      top: 10px;
      font-family: 'sz-icon-line' !important;
      padding-right: 20px;
    }

    &:after {
      background: none !important;
    }

    &.sz-icon-line {
      font-size: 1rem;
      font-family: 'DINPro-Regular' !important;
      cursor: pointer;
    }
    font-family: 'DINPro-Regular' !important;
    background-color: var(--table-list-header-bg);
  }
  tr{
    cursor: pointer;
    color: var(--primary-dark) !important;
    &.selected-project{
      background: var(--light);
    }
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--gray-0);
  }
}

.sz-form-group__sz-input-text {
  -webkit-box-align: center;
  align-items: center;
  background-color: white !important;
  box-shadow: none;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 58px;
  position: relative;
  box-sizing: border-box;
  border-color: rgb(231, 232, 238) !important;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  transition: all 100ms ease 0s;
  outline: 0px !important;

  &:hover {
    border-color: black !important;
    border-width: 1px;
  }
}
.sz-form-group__input-group-icon {
  &.reset-search {
    left: auto;
    right: 10px;
  }
}
.sz-aside {
  .sz-aside-exit-done {
    width: 48px;
  }
}
iframe
{
  border: 0px;
}

.border, .border-right, .border-bottom, .border-left, .border-top {
  border-right: 2px solid var(--blue-4) !important;
}
.programming-menu-item {
  height: 39px;
  width: 24px;
}
.programming-icon {
  height: 39px;
  width: 24px;
    &:before {
      content: url('../src/assets/img/programming.svg');
    }
  &.map-icon{
    margin-top: -10px;
    float: left;
  }
}

.plant-icon {
  height: 39px;
  width: 24px;
  &:before {
    content: url('../src/assets/img/plant.svg');
  }
}
.alm-version
{
  position: fixed;
  bottom: 0;
  right: 0;
  color: #9fa9b7;
}

.btn-primary-brand {
  color: var(--white);
}
.sz-form-group__input{border: 1px var(--gray-500) solid;}

@media (max-width: 1199px) {
  .sz-aside {
    display: none !important;
  }
}

.right-tab {
  padding-right: 30px;
  .RRT__tabs {
    float: right;
  }
}