.alm-ev-header {
  height: 45px;

  h1.page-title {
    font-size: 1.5rem;
    margin-bottom: 0;
    display: flex;
    align-self: center;
    margin-left: 20px;
  }

  &.unlogged {
    padding-right: 20rem;
  }
  @at-root #{&}__brand {
    padding: 8px 0;
  }
  @at-root #{&}__menu-icon {
    font-size: 2rem;
    justify-content: flex-end;
    padding-right: 13px;
    cursor: pointer;
    display: inline-flex;
    width: 50px;
    transition: width 0.5s ease;

    &__text{
      display: block;
      position: absolute;
      left: 60px;
      top:5px;
      font-size: 1.5rem;
      line-height: 2.8rem;
      transition: .25s ease;
    }

    span {
      background: #030e40;
      display: block;
      height: 4px;
      left: 7px;
      top: 18px;
      opacity: 1;
      position: absolute;
      transition: .25s ease-in-out;
      width: 12px;
    }

    &.menuIsOpen {
      width: 200px;

      .alm-ev-header__menu-icon__text{
        opacity: 1;
      }

      span:nth-child(1){
        left: 16px;
        top: 15px;
      }
      span:nth-child(2){
        left: 23px;
        top: 15px;
      }
      span:nth-child(3){
        left: -20px;
        opacity: 0;
      }
      span:nth-child(4){
        left: 50px;
        opacity: 0;
      }
      span:nth-child(5){
        left: 16px;
        top: 22px;
      }
      span:nth-child(6){
        left: 23px;
        top: 22px;
      }
      span:nth-child(2), span:nth-child(5){
        transform: rotate(-45deg);
      }
      span:nth-child(1), span:nth-child(6){
        transform: rotate(45deg);
      }
    }
    &.menuIsClose {

      .alm-ev-header__menu-icon__text{
        opacity: 0;
      }

      span:nth-child(1), span:nth-child(2){
        top: 10px;
      }

      span:nth-child(3), span:nth-child(4){
        top: 18px;
      }

      span:nth-child(5), span:nth-child(6){
        top: 26px;
      }

      span:nth-child(2n){
        left: 24px;
      }
      span:nth-child(2n+1){
        left: 12px;
      }

    }

  }
  @at-root #{&}__search-form {
    padding: 2px;

  }
  @at-root #{&}__app-icon {
    font-size: 1.7rem;
  }
  @at-root #{&}__notif-icon {
    font-size: 2rem;
  }
  @at-root #{&}__signin {
    font-size: 1.8rem;
  }
  @at-root #{&}__signin-text {
    font-size: 1rem;
  }
  @at-root #{&}__brand-title {
    font-size: 2rem;
    padding-top: 7px;
  }
}
.alm-ev-sub-header {
  height: 52px;
}

.notification-urba {
  .sz-notification-number {
    padding: 1px 3px;
    right: -11px;
    top: -9px;
    min-width: 23px;
  }
  .alarm-bell
  {
    font-family: 'sz-icon-line' !important;
  }
}
